import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { HERO_CTA } from '@glass/common/modules/tests/testIds';
import Logo from '@glass/web/components/App/Logo';
import ButtonIcon from '@glass/web/components/Button/ButtonIcon';
import Container from '@glass/web/components/Layout/Container';
import Grid from '@glass/web/components/base/Unstable_Grid2';
import ArrowForwardIos from '@glass/web/components/base/icons/ArrowForwardIos';
import CloudDownloadRounded from '@glass/web/components/base/icons/CloudDownloadRounded';
import { HERO_SECTION_ID, SECTION_ID } from '@glass/web/modules/content/pages/contentIds';
import { SM, XL } from '@glass/web/modules/theme/breakpointKeys';
import { AMBER_300, GREY_200 } from '@glass/web/modules/theme/colors';
import { CENTER, RIGHT } from '@glass/web/modules/theme/constants';
import { FLEX, SPACE_BETWEEN } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

import ButtonPrimaryCta from '@glass/shared/components/Button/ButtonPrimaryCta';
import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import Cover from '@glass/shared/components/Section/HeroSection/Cover';
import HeroTitleLayout from '@glass/shared/components/Section/HeroSection/HeroTitleLayout';
import { BOTTOM_EDGE, TOP_EDGE } from '@glass/shared/components/Section/SectionSpacer';
import usePresentation from '@glass/shared/components/hooks/usePresentation';
import builderCtaText from '@glass/shared/modules/resumes/builderCtaText';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const useStyles = makeStyles()((theme) => ({
  presentationHeaderContainer: {
    display: FLEX,
    justifyContent: SPACE_BETWEEN,
    flexDirection: 'column',
  },
  presentationLogo: {
    fill: GREY_200,
    margin: theme.spacing(0, 'auto', 5),
    width: '100%',
  },
  presentationTitle: {
    color: AMBER_300,
  },
  container: {
    [theme.breakpoints.down(SM)]: {
      marginBottom: theme.spacing(EXTRA_SMALL_SPACING),
    },
  },
}));

const HeroSectionLayout = React.forwardRef(
  (
    {
      title,
      titleVariant,
      titleClassName,
      subtitle,
      subtitleVariant,
      disableCta,
      maxWidth,
      titleComponent,
      subtitleComponent,
      className,
      containerClassName,
      subtitleClassName,
      id,
      ctaText,
      align,
      ctaLeftIcon,
      ctaColor,
      children,
      childrenMdBreakpoint,
      childrenSmBreakpoint,
      isNoBottomPadding,
      ctaEdge,
    },
    ref,
  ) => {
    const { classes } = useStyles();
    const mainCtaNode = useMemo(() => {
      if (disableCta) {
        return null;
      }

      return (
        <ButtonPrimaryCta color={ctaColor} component={ResumeBuilderLink} data-test-id={HERO_CTA}>
          <ButtonIcon
            icon={typeof ctaLeftIcon === 'undefined' ? CloudDownloadRounded : ctaLeftIcon}
          />
          {ctaText}
          <ButtonIcon fontSize={16} icon={ArrowForwardIos} position={RIGHT} />
        </ButtonPrimaryCta>
      );
    }, [ctaColor, ctaLeftIcon, ctaText, disableCta]);

    const isPresentation = usePresentation();

    return (
      <Cover
        ref={ref}
        className={className}
        containerClassName={containerClassName}
        id={id}
        isNoBottomPadding={isNoBottomPadding}
      >
        <Container isNoYPadding maxWidth={isPresentation ? XL : maxWidth}>
          <Grid container alignItems={CENTER} justifyContent={SPACE_BETWEEN}>
            <Grid md={childrenMdBreakpoint} sm={childrenSmBreakpoint} xs={12}>
              <div
                className={isPresentation ? classes.presentationHeaderContainer : classes.container}
              >
                {isPresentation ? <Logo className={classes.presentationLogo} height={240} /> : null}
                <HeroTitleLayout
                  align={align}
                  ctaEdge={ctaEdge}
                  mainCta={isPresentation ? null : mainCtaNode}
                  subtitle={isPresentation ? null : subtitle}
                  subtitleClassName={subtitleClassName}
                  subtitleComponent={subtitleComponent}
                  subtitleVariant={subtitleVariant}
                  title={title}
                  titleClassName={isPresentation ? classes.presentationTitle : titleClassName}
                  titleComponent={titleComponent}
                  titleVariant={titleVariant}
                />
              </div>
            </Grid>
            {children ? (
              <Grid md={childrenMdBreakpoint !== 12} sm={childrenSmBreakpoint !== 12} xs={12}>
                {children}
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Cover>
    );
  },
);

HeroSectionLayout.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  childrenMdBreakpoint: PropTypes.number,
  childrenSmBreakpoint: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaEdge: PropTypes.oneOf([BOTTOM_EDGE, TOP_EDGE, null]),
  ctaLeftIcon: PropTypes.node,
  ctaText: PropTypes.string,
  disableCta: PropTypes.bool,
  id: PropTypes.string,
  isNoBottomPadding: PropTypes.bool,
  maxWidth: PropTypes.string,
  subtitle: PropTypes.node,
  subtitleClassName: PropTypes.string,
  subtitleComponent: PropTypes.elementType,
  subtitleVariant: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleClassName: PropTypes.string,
  titleComponent: PropTypes.elementType,
  titleVariant: PropTypes.string,
};

HeroSectionLayout.defaultProps = {
  subtitleVariant: 'h2',
  subtitleComponent: 'h3',
  titleVariant: 'h1',
  disableCta: false,
  ctaText: builderCtaText,
  childrenMdBreakpoint: 7,
  childrenSmBreakpoint: 7,
};

export default React.memo(withTrackingContext(HeroSectionLayout, [SECTION_ID, HERO_SECTION_ID]));
